import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Hire = () => {
  const [isOpenContactUsModal, toggleContactUsModal] = useState(false);
  return (
    <Layout>
      <SEO title="foriio Jobsに募集を投稿する" />
      <h1 className="display-1">
        15,000人のクリエイターにリーチできる、foriio Jobs
      </h1>
      <p className="lead">
        こんにちは、foriioの運営チームの山田寛仁です。日本でアートディレクター・デザイナーとして10年以上経験した後、その原体験を元にクリエイター向けのポートフォリオサービスを展開し、クリエイターのコミュニティをforiioを通して構築してきました。
      </p>
      <p className="lead">
        技術的に優秀だったり、意欲的なクリエイターであっても、クリエイターを求めている企業と出会える機会は不足しています。このジョブボードは、そんなクリエイターを雇うのに役立ちます。
      </p>
      <div className="section">
        <div className="container">
          <h2 className="display-2">foriio Jobsのメリット</h2>
          <p>
            個人の実績をまとめられるポートフォリオサービスから始まったforiioは、クリエイティブ業界でのキャリアのスタートや成長を支援するためのサイトへと発展してきました。
          </p>
          <p>
            2019年1月から、クリエイターのポートフォリオプラットフォームとしてスタートしました。個人が自分たちで活用できるツールとして提供されたツールが、今や15,000人以上のクリエイターに使われるプラットフォームになりました。
          </p>
          <p>
            その過程で徐々に仕事を探している人達と、クリエイターを探している人達の架け橋が求められている事に気づき、foriio
            jobsという求人情報をスタートしました。クリエイターの方々に新しい機会を提供できるきっかけとなれば幸いです。
          </p>
          <div className="row">
            <div className="col-sm">
              <div className="card shadow">
                <div className="card-body">
                  <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                    <i className="ni ni-single-02" />
                  </div>
                  <h6 className="h6 text-primary">
                    15,000人のクリエイターにアプローチ
                  </h6>
                  <p className="description">
                    クリエイターが多く集まるポートフォリオサービスのforiio。業界経験者・未経験者、学生、インターン希望など幅広くいるため、様々なタイプの意欲的なクリエイターの採用が見込めます。
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div className="card shadow">
                <div className="card-body">
                  <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                    <i className="ni ni-album-2" />
                  </div>
                  <h6 className="h6 text-warning">
                    ポートフォリオで確認できる
                  </h6>
                  <p className="description">
                    foriio
                    Jobsなら、実績確認もforiioで確認できるので手間なく候補者のスキルを把握できます。ポートフォリオを作っている「新しい機会との出会いに意欲的」なクリエイターと出会えます。
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div className="card shadow">
                <div className="card-body">
                  <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
                    <i className="ni ni-credit-card" />
                  </div>
                  <h6 className="h6 text-success">親切な価格プラン</h6>
                  <p className="description">
                    他のクリエイティブ系求人サイトと比べて掲載料も良心的でシンプルです。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <h2>求人情報のお問い合わせにあたり</h2>
          <p>
            foriio
            Jobsでは、弊社の「個のクリエイターをエンパワーメントする。」というミッションを実現すべく、最低限必要な企業様の情報・素材をご支給いただいた上で、「求人掲載」に関しての可否を判断させて頂いております。お客様の満足度を担保したいと考え、ご満足いただける結果が見込めない企業様に関しては、僭越ながら掲載をお断りさせて頂く可能性もございますので予めご了承ください。
          </p>
          <Button
            variant="primary"
            size="lg"
            block
            className="my-4"
            onClick={() => toggleContactUsModal(true)}
          >
            求人情報掲載の申し込みはこちらから
          </Button>
        </div>
      </div>
      <Modal
        size="lg"
        centered
        block
        show={isOpenContactUsModal}
        onHide={() => toggleContactUsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>お問い合わせ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            料金などの詳細については
            <a href="mailto:jobs@foriio.com">jobs@foriio.com</a>
            へお問い合わせ下さいませ。お問い合わせ頂く際、投稿予定の求人情報も合わせてお送りください。foriio
            Jobsでは、弊社の「個のクリエイターをエンパワーメントする。」というミッションを実現すべく、最低限必要な企業様の情報・素材をご支給いただいた上で、「求人掲載」に関しての可否を判断させて頂いております。お客様の満足度を担保したいと考え、ご満足いただける結果が見込めない企業様に関しては、僭越ながら掲載をお断りさせて頂く可能性もございますので予めご了承ください。
          </p>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Hire;
